<template>
  <div class="lcf-user-info">
    <NavBar
      title="个人信息"
      left-arrow
      fixed
      @click-left="$router.replace({name: 'User'})"
      placeholder
    ></NavBar>
    <CellGroup class="lcf-user-info-p1">
      <Cell title="账号" :value="personalInfo.username"></Cell>
      <Cell title="真实姓名" :value="personalInfo.realname"></Cell>
      <Cell
        title="登录密码"
        value="修改"
        is-link
        :to="{ name: 'ResetPwd' }"
        v-waves
      ></Cell>
      <Cell title="身份证号" :value="idCard(personalInfo.idcard)"></Cell>
      <Cell title="银行卡" :value="personalInfo.bindingBankCard"></Cell>
    </CellGroup>

    <CellGroup class="lcf-user-info-p2">
      <Cell title="自动投标" :value="personalInfo.autoinvest"></Cell>
      <Cell title="风险承受能力测评" :value="personalInfo.riskGradeName"></Cell>
    </CellGroup>

    <CellGroup class="lcf-user-info-p3">
      <Cell title="消息中心" is-link :to="{ name: 'Messages' }" v-waves></Cell>
    </CellGroup>
    <CellGroup class="lcf-user-logout">
      <Cell @click="logout" v-waves>
        <template #title>
          <div class="lcf-user-logout-text">退出登录</div>
        </template>
      </Cell>
    </CellGroup>
  </div>
</template>

<script>
import { NavBar, Cell, CellGroup, Notify } from 'vant'
import { mapActions } from 'vuex'

export default {
  components: {
    NavBar,
    Cell,
    CellGroup,
  },
  data() {
    return {
      personalInfo: {
        username: '',
        realname: '',
        idcard: '',
        bindingBankCard: '',

        riskGradeName: '',
      },
    }
  },
  created() {
    this.showPersonalBaseInfo()
    this.showPersonalInfo()
  },
  methods: {
    ...mapActions([
      'requestUserInfo',
      'requestPersonalInfo',
      'clearLoginStatus',
    ]),
    async showPersonalBaseInfo() {
      let error, data
      ;[error, data] = await to(this.requestUserInfo())

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data) {
        this.personalInfo.username = data.username
        this.personalInfo.realname = data.realname
        this.personalInfo.idcard = data.idcard

        this.personalInfo.riskGradeName = data.risk_grade_name
      }

      return data
    },
    async showPersonalInfo() {
      let error, data
      ;[error, data] = await to(this.requestPersonalInfo())

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data) {
        this.personalInfo.bindingBankCard = data.bankcard.value
        this.personalInfo.autoinvest = data.autoinvest.value
      }

      return data
    },

    logout() {
      this.clearLoginStatus()
      Notify({
        type: 'success',
        message: '已退出登录',
      })
      this.$router.push({ name: 'Home' })
    },
  },
  computed: {
    idCard() {
      return (idcard) => {
        let ret = ''
        if (
          Object.prototype.toString.call(idcard) === '[object String]' &&
          idcard.length > 15
        ) {
          ret = [...idcard].fill('*', 6, 14).join('')
        }
        return ret
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.lcf-user-info {
  background: $lcf-color-bg-gray-light;
  height: 100%;
  .lcf-user-info-p1 {
    //
  }
  .lcf-user-info-p2,
  .lcf-user-info-p3,
  .lcf-user-logout {
    margin: 8px 0 0;
    .lcf-user-logout-text {
      color: $lcf-color-default;
      text-align: center;
    }
  }
}
</style>
